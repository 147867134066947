/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background: linear-gradient(to bottom, #444, #222);
  background-size: cover;
  background-attachment: fixed;
}

.App {
  height: 100%;
  overflow-y: scroll;
}

/* Container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px; /* Adjusted padding to space out cards */
}

/* Card Styles */
.card {
  width: 50%; /* Adjusted width */
  margin: 20px 0; /* Added margin between cards */
  padding: 20px;
  background-color: #555;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 1.5em;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Introduction Card */
.intro-card {
  justify-content: flex-start;
  text-align: center;
  overflow-y: auto;
  max-height: 60vh; /* Keep intro card height in check */
}

.intro-content {
  max-height: 55vh;
  overflow-y: auto;
  padding: 10px;
}

/* Technique Card */
.technique-card {
  background-color: #444;
  color: white;
  text-align: left;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  width:50%;
}

.technique-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #f39c12;
}

.inner-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.small-box {
  flex: 1 1 calc(50% - 20px);
  background-color: #666;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  text-align: center;
  
}

.small-box-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.expanded-content {
  text-align: left;
  color: white;
}

.small-box.expanded {
  flex: 1 1 40%; /* Make the box take 40% of the width */
  position: fixed;
  top: 5vh;
  left: 30vw; /* Center the box horizontally */
  right: 30vw;
  bottom: 5vh;
  background-color: #444;
  z-index: 1000;
  padding: 40px;
  overflow-y: auto;
  border-radius: 20px;
  transition: all 0.5s ease;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}
